import { gql } from '@apollo/client';

export const IMPORT_APPEALS_HISTORIES = gql`
  query ImportAppealsHistories(
    $filter: ListImportAppealsHistoryFilter
    $sort: [SortInput!]
  ) {
    importAppealsHistories(filter: $filter, sort: $sort) {
      count
      data {
        batchId
        createdAt
        fieldMappings
        fileSize
        status
        totalRecords
        duplicateCount
        skipJudgementCount
        updatedAt
        visibility
        isDeleted
      }
    }
  }
`;

export const GET_CSV_FILE_UPLOAD_SIGNED_URL = gql`
  query GetCsvFileUploadSignedUrl($data: GetCsvFileUploadSignedUrlInput!) {
    getCsvFileUploadSignedUrl(data: $data) {
      key
      signedUrl
    }
  }
`;
