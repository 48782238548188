import { gql } from '@apollo/client';

const FETCH_PLAN_PURCHASED_LIST = gql`
  query SubscriptionPlanPurchasesAdmin(
    $filter: SubscriptionPlanPurchasesAdminFilter!
    $sort: [ListSubscriptionPlanPurchasesSort!]
  ) {
    subscriptionPlanPurchasesAdmin(filter: $filter, sort: $sort) {
      data {
        id
        featureConfig
        features
        createdAt
        expireDate
        features
        invoiceShortUrl
        purchaser {
          firstName
          lastName
          phoneNumber
        }
        status
        usageCount
        subscriptionPlan {
          creator {
            firstName
          }
          title
          type
          price
        }
        billing {
          price
        }
      }
      count
    }
  }
`;

const FETCH_TEMPLATES_PURCHASED_LIST = gql`
  query Billing($filter: TemplatePurchasesAdminFilter!) {
    templatePurchasesAdmin(filter: $filter) {
      count
      data {
        createdAt
        expireDate
        billing {
          price
        }
        id
        purchaser {
          firstName
          lastName
          phoneNumber
        }
        status
        template {
          price
          title
          category {
            name
            id
          }
          subCategory {
            name
            id
          }
        }
      }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { FETCH_PLAN_PURCHASED_LIST, FETCH_TEMPLATES_PURCHASED_LIST };
