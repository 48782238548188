import {
  ArrowLeftOutlined,
  CloseCircleOutlined,
  FileExcelOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Button, Card, Col, Form, message, Row, Select, Upload } from 'antd';
import { startCase } from 'lodash';
import Papa from 'papaparse';
import React, { useState } from 'react';
import { mongoClient } from '../../../apollo';
import { ROUTES } from '../../../common/constants';
import { extractCsvHeaders } from '../../../common/utils';
import useRouter from '../../../hooks/useRouter';
import { IMPORT_CSV } from '../graphql/mutations';
import { GET_CSV_FILE_UPLOAD_SIGNED_URL } from '../graphql/queries';

const ExistingFieldsList = [
  'appealNumber',
  'appellant',
  'category',
  'court',
  'respondent',
  'scrapePdfUrl',
  'pdfFileName',
  'assessmentYear',
  'dateOfOrder',
  'bench',
  'appealType',
  'dateOfPronouncement',
  'appealFiledBy',
  'appealFiledOn',
  'dateOfFirstHearing',
  'dateOfLastHearing',
  'dateOfNextHearing',
  'dateOfFinalHearing',
  'benchAllotted',
  'caseStatus',
  'orderResult',
  'judges',
  'sections',
  'resultInFavourOf',
  'assesseeLawyers',
  'departmentLawyers',
  'keywords',
  'issuePeriod',
  'region',
];

const requiredFieldsList = [
  /* kept for future use */
  // 'category',
  // 'court',
  // 'bench',
  // 'scrapePdfUrl',
  // 'pdfFileName',
  // 'appealNumber',
];

const CsvImportModal = ({ onClose }) => {
  const [form] = Form.useForm();
  const { navigate } = useRouter();
  const [fileList, setFileList] = useState([]);
  const [csvHeaders, setCsvHeaders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedFields, setSelectedFields] = useState(
    Array(ExistingFieldsList.length).fill(null),
  );

  const validateRow = (row, requiredFields) =>
    requiredFields.every((field) => row[field] && row[field] !== '');

  const GetSignedUrlAndImport = async ({ variables }) => {
    try {
      const data = await mongoClient?.query({
        query: GET_CSV_FILE_UPLOAD_SIGNED_URL,
        fetchPolicy: 'network-only',
        variables,
      });
      setLoading(false);
      return data?.data;
    } catch (error) {
      return error;
    }
  };

  const uploadImportCSV = async ({ variables }) => {
    try {
      const data = await mongoClient?.mutate({
        mutation: IMPORT_CSV,
        fetchPolicy: 'network-only',
        variables,
      });
      setLoading(false);
      if (data?.data) {
        setFileList([]);
        setCsvHeaders([]);
        setSelectedFields([]);
        form.resetFields();
        navigate(ROUTES.IMPORT_CSV);
      }
    } catch (error) {
      setLoading(false);
      // eslint-disable-next-line no-console
      console.log('eror', error);
    }
  };

  const swapKeysAndValues = (obj) =>
    Object.fromEntries(Object.entries(obj).map(([key, value]) => [value, key]));

  const handleSubmit = async (values) => {
    setLoading(true);
    const swappedObj = swapKeysAndValues(values);
    const requiredFields = Object.entries(values)?.filter(([key]) =>
      requiredFieldsList?.includes(key),
    );
    const requiredCSVFields = requiredFields?.map((e) => e[1]);
    const file = fileList?.[0]?.originFileObj;

    if (file) {
      Papa.parse(file, {
        header: true,
        complete: async ({ data }) => {
          const sampleSize = Math.ceil(data?.length * 0.2);
          const sampledRows = data
            .sort(() => 0.5 - Math.random())
            .slice(0, sampleSize);

          const validRows = sampledRows.filter((row) =>
            validateRow(row, requiredCSVFields),
          );
          const isValid = validRows.length === sampleSize;
          if (isValid) {
            const signUrlResponse = await GetSignedUrlAndImport({
              variables: { data: { fileName: file?.name } },
              fieldMappings: swappedObj,
            });
            const signedUrl =
              signUrlResponse?.getCsvFileUploadSignedUrl?.signedUrl;
            if (signedUrl) {
              try {
                setLoading(true);
                const response = await fetch(signedUrl, {
                  method: 'PUT',
                  body: file,
                });
                if (response?.status === 200) {
                  await uploadImportCSV({
                    variables: {
                      data: {
                        filePath:
                          signUrlResponse?.getCsvFileUploadSignedUrl?.key,
                        fieldMappings: swappedObj,
                      },
                    },
                  });
                }
              } catch (error) {
                // eslint-disable-next-line no-console
                console.log('eror', error);
              }
            }
          } else {
            message.error('Uploaded file is not valid');
            setLoading(false);
          }
        },
        error: (error) => {
          // eslint-disable-next-line no-console
          console.error('Error parsing CSV:', error);
        },
      });
    } else {
      setLoading(false);
    }
  };

  const handleUploadChange = async ({ fileList: newFileList }) => {
    setFileList(newFileList);
    if (newFileList.length > 0) {
      const file = newFileList[0].originFileObj;
      const headers = await extractCsvHeaders(file);
      setCsvHeaders(headers);
    }
  };

  const handleCancel = () => {
    setFileList([]);
    setCsvHeaders([]);
    setSelectedFields([]);
    form.resetFields();
    onClose();
  };

  const props = {
    onRemove: (file) => {
      setFileList((prev) => {
        const index = prev.indexOf(file);
        const newFileList = prev.slice();
        newFileList.splice(index, 1);
        return newFileList;
      });
    },
    beforeUpload: (file) => {
      const isCsv =
        file.type === 'text/csv' || file.type === 'application/vnd.ms-excel';
      if (!isCsv) {
        message.error('You can only upload CSV files!');
      }
      return isCsv;
    },
    fileList,
    onChange: handleUploadChange,
    accept: '.csv',
  };

  const handleFieldChange = (index, value) => {
    const newSelectedFields = [...selectedFields];
    newSelectedFields[index] = value;
    setSelectedFields(newSelectedFields);
  };

  const handleClear = (field) => {
    setSelectedFields((prev) => {
      const updated = { ...prev };
      delete updated[field];
      return updated;
    });
  };

  const CsvFieldMappingForm = ({ headers }) => (
    <div className="mt-40 mapping-form">
      <Row gutter={16}>
        {ExistingFieldsList?.map((header, index) => (
          <Col key={header} span={8}>
            <Form.Item
              label={startCase(header)}
              name={header}
              rules={[
                {
                  required: requiredFieldsList?.includes(header),
                  message: `Please select ${startCase(header)}`,
                },
              ]}
            >
              <Select
                placeholder={`Select ${startCase(header)}`}
                onChange={(value) => handleFieldChange(index, value)}
                allowClear
                showSearch
                onClear={() => handleClear(header)}
              >
                {headers
                  .filter((csvHeader) => !selectedFields.includes(csvHeader))
                  .map((csvHeader) => (
                    <Select.Option key={csvHeader} value={csvHeader}>
                      {csvHeader}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        ))}
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item className="mt-24">
            <Button className="mr-12" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              Validate CSV & Import
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );

  return (
    <>
      <div className="title">
        <Button
          type="text"
          shape="circle"
          onClick={() => {
            navigate(ROUTES.IMPORT_CSV);
          }}
          icon={<ArrowLeftOutlined />}
        />
        Add Batch
      </div>

      <Card className="ant-body-scroll">
        <div className="card-body-wrapper">
          <Form form={form} layout="vertical" onFinish={handleSubmit}>
            <div className="file-uploaded">
              {fileList?.length === 0 ? (
                <div>
                  <Upload.Dragger {...props} multiple={false}>
                    <p className="ant-upload-drag-icon">
                      <UploadOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Drag & drop your CSV file here
                    </p>
                    <p className="ant-upload-hint">or click to select file</p>
                  </Upload.Dragger>
                </div>
              ) : (
                <div className="uploaded-file-view">
                  <div className="file-shower">
                    <FileExcelOutlined className="file-preview" />
                    <div className="close-icon">
                      <Button
                        icon={<CloseCircleOutlined />}
                        type="primary"
                        shape="rounded"
                        danger
                        disabled={false}
                        onClick={() => {
                          setFileList([]);
                          setSelectedFields([]);
                          setCsvHeaders([]);
                          form.resetFields();
                          onClose?.();
                        }}
                      />
                    </div>
                  </div>
                  <div className="body-meta mt-12 file-name">
                    {fileList?.[0]?.name}
                  </div>
                </div>
              )}
            </div>
            {fileList?.length > 0 && (
              <CsvFieldMappingForm headers={csvHeaders} />
            )}
          </Form>
        </div>
      </Card>
    </>
  );
};

export default CsvImportModal;
